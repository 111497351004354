// @ts-nocheck
"use client"
import { TicketX  } from 'lucide-react'
import { Button } from '@/components/ui/button'
import Link from 'next/link'

const error = ({
    error,
    reset
}: {
    error: Error
    reset: () => void
}) => {
    return (
        <>
          <div className="max-w-4xl mx-auto text-center"></div>
          <main className="flex items-center justify-center min-h-screen px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
              <TicketX className='h-20 w-20 mx-auto' />
       
      {/*         <p className="text-base font-semibold">Oops... There is an error.</p> */}
              <h1 className="text-3xl font-bold tracking-tight sm:text-5xl">
                {error.message || "Oops... There is an error."}</h1> 
     {/*          <p className="mt-6 text-base leading-7">Sorry, we couldn’t find the page you’re looking for.</p> */}
              <div className="mt-4 flex items-center justify-center gap-x-6">

                <Button onClick={reset}>
                    Try again              
                </Button>
              </div>

              <div className="mt-4 flex items-center justify-center gap-x-6">
                <Link href="/dashboard" >
                    Go to dashboard
                </Link>
            </div>
            </div>
          </main>
        </>
      )
}
   

export default error


/* import Link from 'next/link'
import Skeleton from 'react-loading-skeleton'
import { Ghost } from 'lucide-react'
import { Button } from '@/components/ui/button'

export default function NotFound() {
  return (
    <>
      <div className="max-w-4xl mx-auto text-center"></div>
      <main className="flex items-center justify-center min-h-screen px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <Ghost className='h-20 w-20 mx-auto' />
          <p className="text-base font-semibold">404</p>
          <h1 className="text-3xl font-bold tracking-tight sm:text-5xl">Oops, page not found</h1>
          <p className="mt-6 text-base leading-7">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Skeleton height={100} className='my-2' count={3} />
            <Button>
              <Link href="/dashboard">
                Go to dashboard
              </Link>
            </Button>
          </div>
        </div>
      </main>
    </>
  )
}
 */